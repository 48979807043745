import React, { useState, useEffect } from "react";
import cube from "../../../images/cube.png";
import api from "../../../helper/auth_interceptor";
import { getUser } from "../../../helper/auth_helper";
import homeAccordian from "../../../images/home-accordian.png";
import babyModalReplacement from "../../../images/abc.png";
import addressDeatils from "../../../images/address-detail.png";
import addressDocuments from "../../../images/address-document.png";
import { exportBuildingReport } from "./api/downloadReports";
import { useAccess } from "../context/accessControl";
import { AddressList } from "./addressList";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import CopyToClipboardButton from "./pathComponents/tooltip/CopyToClipboardButton";
export const BuildingDetailsTable = ({
  item,
  buildingId,
  addressReportList,
  rowHeadData,
  buildingReportList,
  drivePath,
  omnibaseOrgId,
  omniBaseProjectId,
}) => {
  const { suprmenAdmin } = useAccess();
  const { t } = useTranslation();
  const handleButtonClick = (reportId, fileName) => {
    if (suprmenAdmin) {
      exportBuildingReport(reportId, fileName);
    } else {
      toast.error(`${t("fileDownload_errorMessage")}`);
    }
  };
  const [omnibaseUrl, setOmnibaseUrl] = useState("");
  const getVisualizationLink = async () => {
    const user = await getUser();
    const getApi = `${process.env.REACT_APP_API_GATEWAY_URL}/visualization-management/api/get-omnibase-link`;
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };
    try {
      const response = await api.get(getApi, { headers });
      setOmnibaseUrl(response.data);
    } catch (error) {
      console.log("Error:", error);
    }
  };
  useEffect(() => {
    getVisualizationLink();
  }, []);
  return (
    <tr>
      <td colSpan="8">
        <div className="dropdown-content">
          <div className="building-details-block">
            <div className="building-details-heading">
              <img
                className="homeaccordian"
                src={homeAccordian}
                alt="homeAccordian"
              />
              <h2>Building Details</h2>
            </div>
            <div className="building-details-data">
              <div className="building-details-img">
                <img
                  src={
                    item.babyModel
                      ? `data:image/png;base64,${item.babyModel}`
                      : babyModalReplacement
                  }
                  className="babyModalImg"
                  alt="building"
                />
              </div>
              <div className="building-details-sub-data">
                <h2>{item.name}</h2>
                <p>{item.streetName}</p>
                <p>{item.postcode}</p>
                <h2>Pand Id: {item.pandId}</h2>
              </div>
              <div className="building-details-actions">
                <div className="informationButtons">
                  <button
                    className={`actionsBtn3 ${
                      buildingReportList.length > 0 && buildingReportList[0].reportId ? 'active' : ''
                    }`}
                    type="submit"
                    onClick={() =>
                      buildingReportList.length > 0 &&
                      buildingReportList[0].reportId
                        ? handleButtonClick(
                            buildingReportList[0].reportId,
                            buildingReportList[0].fileName
                          )
                        : null
                    }
                    disabled={
                      buildingReportList || !buildingReportList[0].reportId
                    }
                  >
                    <img src={cube} alt="NEN2767" />
                    <a>NEN2767</a>
                  </button>
                  {drivePath && suprmenAdmin &&(
                    <CopyToClipboardButton link={drivePath} />
                  )}
                  {/* {buildingReportList.map((reportItem, index) => (
                    <React.Fragment key={index}>
                      <button
                        className="actionsBtn3"
                        type="submit"
                        onClick={() =>
                          reportItem.reportId
                            ? handleButtonClick(reportItem.reportId, reportItem.fileName)
                            : null
                        }
                        disabled={!reportItem.reportId}
                      >
                        <img src={cube} alt="NEN2767" /><a>{reportItem.reportType}</a>
                      </button>
                      {suprmenAdmin && buidingIndex && (
                        <Tooltip drivePath={drivePath} />
                      )}
                      <button
                        className="buidingButtonsInformation"
                        onMouseEnter={() => setBuidingIndex(true)}
                        onMouseLeave={() => setBuidingIndex(false)}
                      >
                     <img src={information} alt="information" />
                      </button>
                    </React.Fragment>
                  ))} */}
                </div>
                {omniBaseProjectId ? (
                  <button className="exploreButton">
                    <img src={cube} alt="explore in 3D" />
                    <a
                      href={`${omnibaseUrl}/projects/${omniBaseProjectId}/view?org_uuid=${omnibaseOrgId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Explore in 3D
                    </a>
                  </button>
                ) : (
                  <button className="actionsBtn3" disabled>
                  <img src={cube} alt="explore in 3D" />
                    <a>Explore in 3D</a>
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="address-documents">
            <div className="address-heading-block">
              <div className="address-details">
                <img
                  className="addressDeatils"
                  src={addressDeatils}
                  alt="addressDeatils"
                />
                <h2>Address details</h2>
              </div>
              <div className="centered-content">
                <img
                  className="addressDocuments"
                  src={addressDocuments}
                  alt="addressDocuments"
                />
                <h2>Documents</h2>
              </div>
            </div>
            <div className="address-table-content">
              <AddressList
                buildingId={buildingId}
                addressReportList={addressReportList}
                rowHeadData={rowHeadData}
              />
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
};




